import * as Sentry from '@sentry/remix';
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import { useEffect } from 'react';
import appStylesHref from '~/front/styles/app.css?url';
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
  useLocation,
  useMatches,
} from '@remix-run/react';
import { LinksFunction, MetaFunction } from '@remix-run/node';
import { ToastContainer } from 'react-toastify';
import { GoogleReCaptchaProvider } from '@google-recaptcha/react';
import { typedjson } from 'remix-typedjson';
import markdownStylesheet from '~/front/styles/markdown.css?url';

import toastStylesheet from 'react-toastify/dist/ReactToastify.css?url';
import toastOverrideStylesheet from '~/front/styles/toast.css?url';
import tailwindStylesheet from '~/front/styles/tailwind.css?url';
import { RECAPTCHA_SITE_KEY } from '~/server/utils/verify_recaptcha.server';

export const meta: MetaFunction = () => {
  return [{ title: 'UpCare' }, { name: 'description', content: 'UpCare' }];
};

export const links: LinksFunction = () => [
  { rel: 'icon', href: '/favicon.png', type: 'image/png' },
  { rel: 'stylesheet', href: appStylesHref },
  { rel: 'stylesheet', href: tailwindStylesheet },
  { rel: 'stylesheet', href: toastStylesheet },
  { rel: 'stylesheet', href: toastOverrideStylesheet },
  { rel: 'stylesheet', href: markdownStylesheet },
];

export function ErrorBoundary() {
  const error = useRouteError();

  const BaseErrorLayout = ({ children }: { children: React.ReactNode }) => (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>{children}</body>
    </html>
  );

  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    return (
      <BaseErrorLayout>
        <main className="w-full h-screen grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
          <div className="text-center">
            <p className="text-base font-semibold text-primary">
              {error.status}
            </p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              {error.statusText !== '' ? error.statusText : 'Error'}
            </h1>
            <p className="mt-6 text-base leading-7 text-gray-600">
              {error.data}
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" className="text-sm font-semibold text-gray-900">
                Contact support <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </main>
      </BaseErrorLayout>
    );
  } else if (error instanceof Error) {
    return (
      <BaseErrorLayout>
        <div>
          <h1>Error</h1>
          <p>{error.message}</p>
          <p>The stack trace is:</p>
          <pre>{error.stack}</pre>
        </div>
      </BaseErrorLayout>
    );
  } else {
    return (
      <BaseErrorLayout>
        <h1>Unknown Error</h1>
      </BaseErrorLayout>
    );
  }
}

export const loader = async () => {
  return typedjson({
    ENV: {
      SENTRY_DSN: process.env.SENTRY_DSN,
      RECAPTCHA_SITE_KEY: RECAPTCHA_SITE_KEY,
      CSV_MAX_SIZE_MB: process.env.CSV_MAX_SIZE_MB,
      CSV_MAX_LINES: process.env.CSV_MAX_LINES,
    },
  });
};

function App() {
  const { ENV } = useLoaderData<typeof loader>();

  if (ENV.SENTRY_DSN) {
    Sentry.init({
      dsn: ENV.SENTRY_DSN,
      tracesSampleRate: 1,

      // 全てのリクエストに載っている分散トレーシング用の「Baggage」というヘッダが無限増殖するバグがあるため、分散トレーシングを無効化してヘッダを消している Ref: https://github.com/getsentry/sentry-javascript/issues/12350
      // これを無効化してもフロントエンドのエラーは問題なく検知できることを確認済み
      tracePropagationTargets: [],

      integrations: [
        Sentry.browserTracingIntegration({
          useEffect,
          useLocation,
          useMatches,
        }),
      ],
    });
  }

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="flex">
        <GoogleReCaptchaProvider
          type="v2-checkbox"
          siteKey={ENV.RECAPTCHA_SITE_KEY || ''}
        >
          <Outlet />
          <ToastContainer position="bottom-center" />
          <ScrollRestoration />
          <Scripts />
        </GoogleReCaptchaProvider>
      </body>
    </html>
  );
}

export default withSentry(App);
